import React from "react";
import { PageProps } from "gatsby";
import pagesMeta from "data/pagesMeta";
import { SpotlightList } from "components/customers";
import { BlogSection } from "components/blog";
import SEO from "components/SEO";

const BlogCategoryPage: React.FC<PageProps> = ({ pageContext, location }) => {
  const {
    posts,
    categories,
    spotlights: { edges: spotlights },
  } = pageContext as any;

  return (
    <>
      <SEO {...pagesMeta.blog} />
      <BlogSection sections={categories} posts={posts} location={location} />
      <SpotlightList
        spotlights={spotlights[0].node.data as any}
        theme="light"
      />
    </>
  );
};

export default BlogCategoryPage;
